/* Gallery Module Custom Styles */

.gallery-main .owl-next {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	font-family: monospace;
	font-size: 27px;
	display: block;
	height: 30px;
	width: 30px;
	background-color: $brand;
	text-align: center;
	line-height: 28px;
	border-radius: 99999px;
	color: #fff;
}

.gallery-main .owl-prev {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
  font-family: monospace;
  font-size: 27px;
  display: block;
  height: 30px;
  width: 30px;
  background-color: $brand;
  text-align: center;
  line-height: 28px;
  border-radius: 99999px;
  color: #fff;
  }

.gallery-main {
	position: relative;
	padding: 0px 6%;
	margin: 0 auto 20px;
}
