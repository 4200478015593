/*

  $brand will be the primary project colour used in things like buttons and links, this can be changed in the variables.scss file.

*/


/* General Styles */

body {
    font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Open Sans', sans-serif;
}

iframe{
    max-width: 100%;
}

.row {
    width: 90%;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-flow: row wrap;
}

.row.expanded {
    max-width: 100%;
    width: 100%;
    margin: auto !important;
}

#breadcrumbs {
    background: #f0f3f4;
    padding: 1em;
    font-size: 0.9em;
}

#breadcrumbs a{
    color: #161616;
}

.product-info {
    background: #161616;
    color: $brand;
    padding: 1%;
}

.product-card {
    border: 0;
    border-top: 1px solid #CBCBCB;
    padding-top: 1%;
    padding-bottom: 1%;
}

.product-card-cont {
    margin-bottom: 0!important;
}

.product-card a:hover {
    background: 0;
}

.product-card:hover a {
    color: #ffffff;
}

.product-page-row{
    margin: auto!important;
    width: 100%;
}

.mainProductImage{
    width: 98%;
    margin: 1%;
}

.thumbnailProduct {
    width: 23%;
    float: left;
    margin: 1%;
}

.display-small{
    display: none;
}

.mfp-arrow:before{
    display: none!important;
}

/* - Top Bar Styles - */

#header {
    background-color: #161616;
    text-align: center;
    z-index: 999;
    width: 100%;
    position: fixed;
    .row {
        height: 150px;
    }
    .menu-icon {
        margin-left: 2em;
    }
    & > div {
        background-color: #161616;
    }
    .header-contact-details {
        text-align: left;
        font-size: 15px;
        line-height: 30px;
        
        @media screen and (max-width: 65em) {
            display: none;
        }
        
        a {
            color: #fff;
            
            img {
                margin-right: 5px;
            }
        }
    }
}

.header-left.column {
    padding: 1.3rem 0px;
}

.menu#right-menu {
    float: right;
    width: auto;
}

#right-menu li a {
    color: #1f1f1f;
}

#right-menu li a:hover {
    color: $brand;
}

.menu-icon::after {
    background: #fff;
    box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
}

.menu-button {
    display: none;
}

.button.toggle {
    margin: 0px;
}

.panel-relative {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
}

.simple-panel {
    position: fixed;
    height: 100%;
    width: 400px;
    right: 0;
    top: 0;
    background: $brand;
    list-style-type: none;
    padding: 1em;
    z-index: 9999;
    transform: translateX(100%);
    -webkit-transition: transform cubic-bezier(1, 0.21, 0, 1.07) 0.45s;
    -moz-transition: transform cubic-bezier(1, 0.21, 0, 1.07) 0.45s;
    transition: transform cubic-bezier(1, 0.21, 0.26, 0.98) 0.45s;
    box-shadow: none;
}

.simple-panel.is-active {
    box-shadow: 0.01em 0 0.5em #737373;
    transform: translateX(0);
}

.simple-panel li {
    color: #fff;
    display: block;
    padding: 1rem 1.5rem;
}

.simple-panel a {
    color: #fff;
    display: block;
    padding: 1rem 1.5rem;
}

.simple-panel .close-button {
    position: absolute;
    top: 1rem;
    right: 1rem;
    line-height: 21px;
}

.simple-panel .close-button span {
    color: #fff;
    font-size: 2.5rem;
    font-weight: lighter;
}

.panel-menu {
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
}

#side-menu {
    width: 300px;
    position: fixed;
    background: #2f2f2f;
    height: calc(100% - 150px);
    z-index: 99;
    padding-right: 30px;
    padding-left: 30px;
    transition: 0.3s;
    overflow-y: auto;
    bottom: 0;
    &.open {
        transform: translateX(0);
    }
    li {
        padding-top: 5%;
        padding-bottom: 2%;
    }
    a {
        text-transform: uppercase;
        color: #cacaca;
        font-size: 15px;
        
        &:hover{
            color: #fff;
        }
    }
    p#side-contact {
        text-align: right;
        font-size: 15px;
        color: #cacaca;
        margin-top: 42px;
        line-height: 35px;
        
        a {
            text-transform: none;
        }
    }
    img {
        margin-right: 15px;
    }
    hr {
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 1px solid #8e8e8e;
    }
    .social-icons{
        text-align: right;
        
        a{
            color: $brand;
            font-size: 1.25em;
            margin-left: 10px;
        }
    }
    &::-webkit-scrollbar {
        width: 8px;
    }
    &::-webkit-scrollbar-track {
        background: darken(#2f2f2f, 5%);
    }
    &::-webkit-scrollbar-thumb {
        background: $brand;
    }
}

.main-content {
    padding-left: 300px;
    padding-top: 150px;
}

#top-content {
    background: #000;
    padding-top: 6%;
    padding-bottom: 6%;
    p {
        color: #ffffff;
    }
    .quote {
        font-size: 32px;
        font-weight: 100;
        border-right: 3px solid #ffffff;
    }
    .text {
        padding-left: 6%;
        font-size: 15px;
        p {
            line-height: 2em;
        }
    }
}

#top-content.page-top {
    background: $brand;
    padding-top: 3%;
    padding-bottom: 3%;
    h1 {
        color: #ffffff;
        font-weight: lighter;
    }
    p {
        color: #fff;
        font-size: 25px;
        font-weight: lighter;
    }
}

#page-content {
    padding-top: 5%;
    padding-bottom: 5%;
    p {
        line-height: 2em;
    }
}

#info {
    .bg {
        background: url('../../images/yellow-img.jpg') no-repeat;
        background-size: cover;
    }
    .medium-6 {
        padding: 5%;
        img {
            margin-bottom: 0;
        }
    }
    h3{
        font-size: 22px;
        margin: 30px 0 10px;
        text-transform: uppercase;
        font-weight: 600;
    }
    p {
        font-size: 15px;
        margin: 0;
    }
}

#home-middle {
    background: url(../../images/testimonial-bg.jpg) no-repeat;
    background-size: cover;
    padding-top: 9%;
    padding-bottom: 9%;
    h3 {
        color: #fff;
        text-transform: uppercase;
        font-weight: 700;
        margin-bottom: 27px;
    }
    p {
        color: #ffffff;
        line-height: 2em;
    }
    a {
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        border: 2px solid $brand;
        padding: 1%;
        padding-left: 3%;
        padding-right: 3%;
        line-height: 6em;
        transition: background-color .25s ease-out, color .25s ease-out;
    }
    a:hover {
        background: darken($brand, 5%);
        color: #000000;
    }
}

.info-box {
    background: #000000;
    padding: 6%;
    transition: background-color .25s ease-out, color .25s ease-out;
}

.info-box:hover {
    background: $brand !important;
    color: #000000 !important;
    p {
        color: #000000 !important;
    }
    h3 {
        color: #000000 !important;
    }
}

#options {
    img {
        width: 100%;
    }
    p {
        color: #ffffff;
    }
    h3 {
        color: $brand;
        font-Weight: lighter;
    }
    .medium-4:nth-child(2) .info-box {
        background: #181818;
    }
}

#testimonials {
    padding-top: 8%;
    padding-bottom: 12%;
    background: url(../../images/tractor-fade.jpg) no-repeat center bottom;
    h3 {
        font-size: 60px;
        color: $brand;
        font-family: arial;
        margin-bottom: 1em;
    }
    p {
        font-size: 30px;
        font-weight: lighter;
        margin-bottom: 1em;
    }
    span {
        font-size: 21px;
        font-weight: bold;
    }
}

#partners {
    background: #e6e6e6;
    padding-top: 1%;
    padding-bottom: 1%;
}

footer {
    padding-top: 5%;
    padding-bottom: 5%;
    background: #000;
    padding-left: 5%;
    padding-right: 5%;
    p {
        color: #ffffff;
    }
    ul li {
        color: #ffffff;
        text-align: left;
        margin-bottom: 0.5em;
        
        a{
            color: #fff;
            text-transform: uppercase;
            &:hover{
                color: #8e8e8e;
            }
        }
    }
    a {
        color: #fff;
    }
    span {
        color: $brand;
    }
    .social {
        padding-bottom: 2em;
    }
    img{
        max-width: 80%;
        opacity: 0.9;
        filter: grayscale(95%);
    }
}


/* - Scroll to top - */

.scrollup {
    width: 40px;
    height: 40px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    display: none;
    background-color: #FFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    color: #0A0A0A;
    line-height: 40px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}


/* - Slideshow Styles - */

#slideshow {
    clear: both;
}

.slide {
    position: relative;
    img {
        width: 100%;
        height: auto;
        filter: brightness(85%);
    }
}

.owl-item {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateZ(0) scale(1.0, 1.0);
}

.overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    margin: 0 auto;
    text-align: left;
    width: 100%;
    height: 100%;
}

.overlay-text {
    margin: 0 5%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 800px;
    max-width: 100%;
    padding: 0 5%;
    
    h1 {
        color: #fff;
        font-weight: 100;
        font-size: 55px;
    }
    p {
        color: #fff;
        font-size: 23px;
        font-weight: 100;
    }
}

.slide .button {
    border: 2px solid #fff;
    background: 0 0;
    width: 70%;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 3px;
    padding-top: 2%;
    padding-bottom: 2%;
    font-size: 17px;
    letter-spacing: 3px;
    margin-top: 2em;
}

a.button:hover {
    background: #ffffff;
    color: #000;
}


/* Styles for Testimonial Slider */

.testimonial-slider {
    .owl-dots {
        text-align: center;
    }
    .owl-dot {
        display: inline-block;
        width: 8px;
        height: 8px;
        margin-right: 10px;
        background-color: rgba(10, 10, 10, 0.29);
        border-radius: 50%;
        &:last-of-type {
            margin-right: 0px;
        }
        &.active {
            background-color: $brand;
        }
    }
}

.galleryrow{
    position: relative;
    
    .back{
        position: absolute;
        top: 0;
        right: 0;
        
        a{
            font-size: 14px;
            background: #fff;
            padding: 10px 20px;
        }
    }
}

.popup-gallery .columns {
    padding: 0.5em;
    
    a{
        display: block;
        position: relative;
        
        .category-name{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 1em;
            background: #fefefe;
            color: #1f1f1f;
            border: 1px solid #CBCBCB;
            border-top: none;
        }
        
        &:hover{
            .category-name{
                background: $brand;
                color: #fff;
                border: 1px solid #CBCBCB;
                border-top: none;
            }
        }
    }
}


/* Social Media Icons */

.social .fa {
    color: #fff;
}

.social .fa-circle {
    color: #000;
}

.facebook-icon .fa-circle {
    color: #3b5998;
}

.twitter-icon .fa-circle {
    color: #55ACEE;
}

.youtube-icon .fa-circle {
    color: #cd201f;
}

.linkedin-icon .fa-circle {
    color: #0077b5;
}

.social a:hover .fa-circle {
    color: $brand;
}


/* Contact Form Styles */

.input-group {
    display: block;
}


/* ------------Max-width-media----------- */

@media screen and (max-width: 80em) {
    #options {
        h3 {
            font-size: 1.4em;
        }
    }
    .overlay-text h1 {
        font-size: 34px;
    }
    .overlay-text p {
        font-size: 18px;
    }
    #top-content .quote{
        font-size: 24px;
    }
}

@media screen and (max-width: 65em) {
    #header .row{
        padding-top: 0;
        padding-bottom: 0;
    }
    .logo{
        padding: 10px;
        img{
            max-width: 250px;
        }
    }
    #header .row{
        height: 110px;
    }
    .main-content{
        padding-top: 110px;
    }
    #side-menu{
        height: calc(100% - 110px);
    }
    .menu-button{
        display: block;
        width: 100%;
        text-align: center;
        line-height: 40px;
        background: darken($brand, 2%);;
        text-transform: uppercase;
    }
    .menu-button:hover{
        background: darken($brand, 5%);
    }
    #side-menu {
        transform: translateX(-100%);
        padding-top: 5px;
    }
    .main-content {
        padding-left: 0;
    }
    .gallery-wrap {
        width: 85%;
        margin: 0 auto;
    }
    .gallery-main {
        padding: 0px;
    }
    .gallery-wrap .owl-carousel.gallery-thumbs {
        display: none;
    }
    .gallery-main .owl-prev {
        top: auto;
        bottom: 0;
        left: 15px;
        font-size: 27px;
        height: 40px;
        width: 40px;
        line-height: 38px;
    }
    .gallery-main .owl-next {
        top: auto;
        bottom: 0;
        right: 15px;
        font-size: 27px;
        height: 40px;
        width: 40px;
        line-height: 38px;
    }
    .overlay-text h1 {
        font-size: 30px;
    }
    .overlay-text p {
        font-size: 18px;
    }
    .overlay-text a {
        display: none;
    }
    .quote {
        font-size: 0.8em;
    }
    #options {
        h3 {
            font-size: 1.4em;
        }
    }
    #top-content .text{
        padding-left: .625rem;
        margin-top: 15px;
    }
    #testimonials p{
        font-size: 20px;
    }
    footer{
        font-size: 14px;
    }
}

@media screen and (max-width: 40em) {
    /* - Adds padding to Columns with the .mobile-padding class on small screen sizes - */
    .mobile-padding {
        padding: 0% 6%;
    }
    .simple-panel {
        width: 100%;
    }
    .product-title {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: bold;
    }
    #testimonials p{
        font-size: 15px;
    }
    .product-info{
        display: none;
    }
    .product-card-cont{
        margin-bottom: 10px!important;
    }
    .product-card{
        padding: 20px;
        
        column{
            padding: 0;
        }
    }
    #top-content.page-top h1{
        font-size: 24px;
    }
    #top-content.page-top p{
        font-size: 16px;
    }
    #top-content .quote{
        border: none;
    }
    .overlay-text h1{
        font-size: 20px;
    }
    .overlay-text p{
        font-size: 14px;
    }
    #header .row{
        padding: 0;
    }
    .product-card{
        border-top: 1px solid #cbcbcb;
        .column{
            margin: 5px 0;
        }
        p{
            margin: 0;
        }
        img{
            margin-bottom: 10px;
        }
    }
    .product-card:hover img{
        transform: none!important;
    }
    .product-card:hover a{
        color: #0a0a0a;
    }
    .display-small{
        display: inline;
    }
}


/* ------------Min-width-media----------- */

@media screen and (min-width: 40em) {
    /* Fixes header on scroll for screens above mobile */
    .fixed {
        position: fixed;
        top: 0px;
        z-index: 2;
        width: 100%;
    }
}

/*--------Fonts---------*/

@import url('https://fonts.googleapis.com/css?family=Open+Sans');
