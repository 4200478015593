/* Case Study Module Custom Styles */

.case-card-cont {
	margin-bottom: 2rem;
}

.case-card{
  border: 1px solid #CBCBCB;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.case-card .case-img-wrapper{
  overflow: hidden;
}

.case-card img {
	width: 100%;
	height: auto;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  overflow: hidden;
}

.case-card .case-label a {
	padding: 1rem;
	text-align: center;
	display: block;
  color: #1f1f1f;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.case-card:hover{
  border: 1px solid #fff;
  background-color: $brand;
}

.case-card:hover img{
  transform: scale(1.1);
  opacity: 0.3s;
}

.case-card:hover .case-label a {
  background-color: $brand;
  color: #fff;
}

#news_case_big{
  display: flex;
  flex-flow: row wrap;
}

#news_case_big #mainimage img {
	width: 100%;
}
