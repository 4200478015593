/* Products Module Custom Styles */

div#breadcrumbs {
	margin-bottom: 1.5rem;
}

.product-card-cont {
	margin-bottom: 2rem;
}

.product-card{
  border: 1px solid #CBCBCB;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
  overflow: hidden;
  border-top: 0;
}

.product-card > a{
	overflow: hidden;
	display: block;
}

.product-card .product-img-wrapper{
  overflow: hidden;
}

.product-card img {
  width: 100%;
  height: auto;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  overflow: hidden;
}



.product-card .product-label a {
  padding: 1rem;
  text-align: center;
  display: block;
  color: #1f1f1f;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.product-card:hover{
  background-color: $brand;
}

.product-card:hover img{
  transform: scale(1.1);
  opacity: 0.3s;
}

.product-card:hover .product-label a {
  background-color: $brand;
  color: #fff;
}

ul.thumbs{
  list-style-type: none;
  margin: 0px;
}

.product-left .owl-dots{
  text-align: center;
}

.product-left .owl-dot {
	height: 8px;
	width: 8px;
	background-color: rgba(34,39,40,0.4);
	border-radius: 50%;
	display: inline-block;
	margin-right: 10px;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  transition: 0.3s;
}

.product-left .owl-dot:hover, .product-left .owl-dot.active {
	background-color: $brand;
}

.product-left .owl-pagination {
	text-align: center;
}
